:root {
  --light-gray: #595959;
}

/* Grid
-------------------------------------------------- */

/* Navbar
-------------------------------------------------- */

.navbar-toggle {
    background-color: transparent!important;
    border: none !important;
    box-shadow: none !important;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color:rgba(0,0,0,.1) !important;
}

.navbar-default .navbar-nav>li>a {
    font-weight: bold;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus,
#site-top-nav-container .nav>li>a:hover,
#site-top-nav-container .nav>li>a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0!important;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    background-color: #fff!important;
    color: #333!important;
}

.dropdown-menu {
    border-radius:0;
}


/* Top Nav
-------------------------------------------------- */

#site-top-nav-container,
#site-top-nav {
    line-height: 36px;
    height: 40px;
}

#site-top-nav-container ul {
    margin-bottom: 0;
}

#site-top-nav-container .nav>li>a {
    padding:4px 8px;
}

#site-top-nav {
    padding: 0 5px;
}


/* Main Nav
-------------------------------------------------- */

#site-navbar-container .navbar {
    border-radius: 0;
    border: 0;
}

#site-navbar-container .navbar-dynamic {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

#site-navbar-container .navbar-static .navbar-nav>li>a {
    border-right: 1px solid rgba(255, 255, 255, .1);
}

#main-navigation {
    padding: 1px 1px 0 1px;
}
#main-navigation .dropdown-menu {
    border-top: 0px;
    margin-left: -1px;
}

#main-navigation {
    margin-bottom: 0;
    padding: 0;
}

#main-navigation .tnt-svg {
    font-size: 17px;
}

#main-navigation.navbar-dynamic .hamburger-desktop {
    padding: 4px 15px 0px;
}

#main-navigation.navbar-dynamic .hamburger-desktop .tnt-svg {
    font-size: 20px;
}

#main-navigation.navbar-dynamic .navbar-header .navbar-toggle {
    min-height: 50px;
    margin: 0;
}

#main-navigation.navbar-dynamic .share-container .list-inline.social-share-links li {
    border: none;
}


/* Header
-------------------------------------------------- */

#site-header {
    padding: 15px;
}

#site-header .divider {
    padding: 0 5px;
    color: rgba(0,0,0,.2);
}


/* Typography
-------------------------------------------------- */

h1, .h1, h2, .h2, h3, .h3 {
    margin-top:0;
}

.list-inline.block-links {
    padding: 0;
    font-size: 12px;
    line-height: 12px;
}

.list-inline.block-links li {
    padding: 4px 6px;
}

.list-inline.block-links li:last-child {
    border-right: 0;
}

.list-inline.block-links.pos-top {
    float:right;
    margin:10px 0;
}

.emphasis-h4 .list-inline.block-links.pos-top {
    margin-top:6px;
}

.emphasis-h2 .list-inline.block-links.pos-top {
    margin-top:18px;
}

.heading-color .list-inline.block-links.pos-top {
    margin-top:23px;
}

.heading-color.emphasis-h4 .list-inline.block-links.pos-top {
    margin-top:18px;
}

.heading-color.emphasis-h2 .list-inline.block-links.pos-top {
    margin-top:30px;
}

.list-inline.block-links.pos-bottom {
    margin-top: 10px;
}

.share-container ul.list-inline.social-share-links {
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
}

.share-container .list-inline.social-share-links li {
    border: 1px solid #e6e6e6;
    border-right: 0;
}

@media (max-width: 767px) {
    .share-container ul.list-inline.social-share-links {
        display: flex;
    }
    .share-container .list-inline.social-share-links li {
        width: auto;
        flex-grow: 1;
    }
}

.share-container .list-inline.social-share-links a,
.share-container .list-inline.social-share-links button {
    padding: 5px 0 0;
    width: 100%;
    border: 0px;
}

.list-inline.social-share-links li {
    padding: 0;
}

ul.list-inline.social-share-links :first-child {
    border-left: 0px;
}

.list-inline.social-share-links li a,
.list-inline.social-share-links li button {
    margin-left: 1px;
}

.bullet-nav {
    padding-left: 18px;
}

.jumbotron > :first-child {
    margin-top:0;
}

.jumbotron > :last-child {
    margin-bottom:0;
}

.container .jumbotron {
    border-radius:0;
    box-shadow:2px 2px rgba(0,0,0,.1);
}

.container .jumbotron h1 {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 768px) {
    
    .share-container ul.list-inline.social-share-links {
        text-align: initial;
        margin: 0px;
    }
    
    .share-container .list-inline.social-share-links li {
        width: auto;
        border: 0px;
    }
    
    .share-container .list-inline.social-share-links a,
    .share-container .list-inline.social-share-links button {
        padding: 0px;
    }
    
    .container .jumbotron h1 {
        text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.15);
    }
}


/* Page
-------------------------------------------------- */

#main-page-container {
    padding-top:20px;
    padding-bottom:20px;
}


/* Tabs
-------------------------------------------------- */

.nav-tabs {
    margin-bottom:15px;
}

.nav-tabs>li>a {
    border-radius:0;
}


/* Labels
-------------------------------------------------- */
.label {
    font-weight:normal;
    padding:.3em .6em;
}
.label-default {
    background-color:rgba(0,0,0,.8);
}
.label-muted {
    background-color: #AAA;
}
.label-normal-size {
    font-size: 100%;
}
.label-flag {
    background-color:rgba(0,0,0,.08);
    color:#111;
    text-transform:uppercase;
    line-height:2em;
}
.label-flag-breaking, .label-flag-alert, .label-flag-weather_alert, .label-flag-staff  {
    background-color:#C9302C;
    color:#fff;
}
.label-flag-staff {
    line-height: initial;
    display: block;
}
.label-flag-hot {
    background-color:#da3d00;
    color:#fff;
}

.label-flag-hot a,
.label-flag-hot a:hover{
    color:#fff;
}

/* Tooltips
-------------------------------------------------- */
.tooltip-inner {
    border-radius:0;
}


/* Panels
-------------------------------------------------- */

.panel {
    border-radius:0;
    box-shadow:2px 2px rgba(0,0,0,.1);
}
.panel-heading, .panel-footer {
    border:0;
    border-radius:0;
}
.panel-group .panel,
.list-group-item:first-child,
.list-group-item:last-child {
    border-radius: 0;
}
.panel > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}


/* Alerts
-------------------------------------------------- */
.alert {
    border-radius:0;
    box-shadow:2px 2px rgba(0,0,0,.1);
}


/* Notifications
-------------------------------------------------- */

#notification-area {
    position: fixed;
    bottom:0;
    left:0;
    z-index: 9999;
}
.alert.notification {
    margin-bottom: 2em;
    left: -1em;
    padding-left: 3em;

}


/* Thumbnails
-------------------------------------------------- */
.thumbnail {
    border-radius:0;
    padding:0;
    border-color:transparent;
}


/* Breadcrumbs
-------------------------------------------------- */
.breadcrumb {
    background-color:transparent;
    border-radius:0;
    padding-left:0px;
    padding-right:0px;
}


/* Pagination
-------------------------------------------------- */
.pagination {
    box-shadow:2px 2px rgba(0,0,0,.1);
}


/* Blockquotes
-------------------------------------------------- */
blockquote {
    font-size:24px;
    line-height:32px;
    border:0;
    margin:0;
    padding:0;
}

.asset .asset-body blockquote {
    margin-bottom:24px;
}

blockquote p, .asset .related-sidebar blockquote p, .asset .asset-body blockquote p {
    font-size:24px;
    line-height:32px;
}

blockquote p:last-child, .asset .related-sidebar blockquote p:last-child, .asset .asset-body blockquote p:last-child {
    margin-bottom:0;
}


/* Buttons
-------------------------------------------------- */
.btn {
    border-radius:0;
    font-weight:bold;
    /* a few bootstrap components depend on default .btn font-size, do not change */
}

.btn-lg, .btn-group-lg>.btn, .btn-sm, .btn-group-sm>.btn, .btn-xs, .btn-group-xs>.btn, .form-control, .btn, .tncms-captcha-response {
    border-radius:0;
}


/* Forms
-------------------------------------------------- */
.form-control:focus,
.tncms-captcha-response:focus {
    border-color:rgba(0,0,0,.2);
    box-shadow:none;
}

/* Search forms
-------------------------------------------------- */
.search-form-container {
    background-color: rgba(0,0,0,.05);
}

.search-form-container .centered-content-container {
    padding: 15px;
}

@media screen and (min-width: 992px) {
    .search-form-container.has-image .centered-content {
        max-width: 90%;
    }
}

@media screen and (min-width: 1280px) {
    .container-fullscreen-region .search-form-container .centered-content {
        max-width: 65%;
    }
}

/* Tables
-------------------------------------------------- */

.asset-table.panel .panel-heading h4 {
    margin:5px 0;
}

.asset-table.panel .table-desc {
    border-bottom:1px solid rgba(0,0,0,.1);
}

.asset-table.panel .table-desc p:last-child {
    margin-bottom:0;
}

.table-asset thead th {
    background-color:#eee;
    color:#555;
}

.table-sortable th[data-sort]{
    cursor:pointer;
    -webkit-user-select: none;
    user-select: none;
    position:relative;
}

.table-sortable th.sorting-asc:after, .table-sortable th.sorting-desc:after {
    position:absolute;
    top:0;
    right:0;
    font:normal normal normal 14px/1 FontAwesome;
    line-height:12px;
    background-color:rgba(0,0,0,.3);
    color:#fff;
    width:18px;
    height:18px;
    margin:5px 5px 0 0;
    text-align:center;
}

.table-sortable th.sorting-asc:after {
    content: "\f0de";
    padding-top:7px;
}

.table-sortable th.sorting-desc:after {
    content: "\f0dd";
}

/* Infinity scroll
-------------------------------------------------- */

#infinite-container .card.author .avatar-box .user-profile-link {
    border-radius: 100px;
}

#infinite-container .card.author .media-body {
    vertical-align: middle;
    height: 55px;
}


/* Photo Carousel
-------------------------------------------------- */

.card.photo-carousel .card{
    margin-bottom: 0;
}

.photo-carousel .caption-container {
    padding-top: 10px;
}

.photo-carousel .item .photo-container,
.asset-photo .image {
    background-color:rgba(0,0,0,.9);
}

.photo-carousel .caption-container .card-meta .list-inline,
.modal.fullscreen.modal-image .caption-container .card-meta .list-inline {
    margin: 5px 0 5px 0;
}

.photo-carousel .caption-container .card-meta .list-inline>li:first-child,
.modal.fullscreen.modal-image .caption-container .card-meta .list-inline>li:first-child {
    padding-left:0;
}

/* Assets
-------------------------------------------------- */

/* Base styles used by all asset types */
.asset {
    position: relative;
    padding-bottom: 18px;
}

.asset .related-sidebar {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.asset .related-sidebar > :not(:empty) {
    margin-bottom: 40px;
}

.asset .related-sidebar div:first-child {
    margin-top:0;
}

.asset .related-sidebar div.tncms-region {
    margin-bottom: 0;
}

.asset .asset-masthead {
    margin: 0 0 20px;
    position: relative;
}

.asset-header {
    padding-right: 28px;
}

.asset .asset-header .hammer,
.asset .asset-header .kicker,
.asset .asset-header h1 {
    margin: 0;
}

.tnt-hammer,
.asset .asset-header .hammer {
    font-weight: bold;
    text-transform: uppercase;
    line-height:normal;
}

.asset .asset-header h1 {
    font-size: 24px;
    font-weight: normal;
}

.asset .asset-header .subhead {
    font-size: 16px;
    font-weight: normal;
    margin: 10px 0;
}

.asset .asset-header .meta {
    margin-top: 20px;
}

.asset .share-container.headline-right {
    position: absolute;
    top: 0px;
    right: -15px;
}

.asset .share-container.content-above:not(:empty) {
    margin-bottom:20px;
}

.asset .share-container.content-below {
    margin:20px 0;
}

.asset .asset-masthead.showcase {
    color: #fff;
    margin: 0 -15px 30px;
    padding: 15px 30px;
    background-color: #222;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.asset .asset-masthead.showcase:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
}

.asset .asset-masthead.showcase .asset-header {
    position: relative;
}

.asset .asset-masthead.showcase .asset-byline a,
.asset .asset-masthead.showcase .asset-date,
.asset .asset-masthead.showcase .cm {
    color: #ddd;
}

.asset .asset-masthead.showcase .share-container.headline-right {
    top: 15px;
    right: 15px;
}

.asset .asset-masthead.showcase .social-share-links.icon .social-share-link a {
    color:#fff;
}

.asset .asset-masthead.showcase .hammer,
.asset .asset-masthead.showcase h1 {
    text-shadow: 2px 2px rgba(0,0,0,0.3);
    font-weight: bold;
}

.asset .asset-body > h1, .asset .asset-body > h2, .asset .asset-body > h3 {
    margin-top:0;
    margin-bottom:24px;
}

.asset .asset-body p {
    color:#444;
    font-size:16px;
    line-height:27px;
    margin-bottom:24px;
}

.light .asset .asset-body p {
    color: rgba(255,255,255,.8);
}

.asset .asset-body ul, .asset .asset-body ol {
    margin-bottom: 24px;
}

.asset figure {
    display: block;
}

#asset-content figure .image,
.asset-photo .image,
.inline-image .image,
.panorama-container .image,
.zoom-container .image {
    margin-bottom: 10px;
}

.asset .asset-body figure .caption p, 
.asset #asset-content figure .caption p,
.asset figure .caption, 
.asset figure .caption p,
.asset .photo-carousel .caption-container p,
.caption-container p {
    color: #666;
    font-size: 13px;
    line-height: 1.4em;
    text-shadow: none;
}

.asset figure .caption p {
    margin-bottom: 10px;
}

.asset figure .caption .credit,
.asset .caption-container .credit,
.asset .asset-showcase-image .showcase-caption .credit,
.caption-overlay .credit {
    display: block;
    color: var(--light-gray);
    margin-top: 5px;
    word-wrap: break-word;
}

.asset .related-sidebar .inline-asset {
    margin: 0 0 40px;
}

.asset .inline-asset .inline-heading,
#asset-below .inline-heading {
    font-size:14px;
    text-transform:uppercase;
}

.asset .inline-asset figure {
    margin: 0 auto;
}

.asset .asset-body .asset-tagline p {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}

.asset .asset-tagline {
    padding-top: 20px;
    position: relative;
}

.asset .asset-tagline:before{
    content: " ";
    position: absolute;
    top: 0;
    left: 37.5%;
    width: 25%;
    border-top: 1px solid rgba(0,0,0,.1);
}

.asset .asset-label-flags {
    margin-bottom:10px;
}

.asset .asset-tags {
    margin-bottom: 20px;
}

.asset .asset-tags h4 {
    margin: 0 15px 0 0;
    float: left;
}

.asset .asset-tags .list-inline {
    margin: 0 0 0 60px;
}

.asset .asset-tags .list-inline > li,
.card.classifieds-categories .list-inline > li{
    padding: 0;
    margin-bottom: 4px;
}

.asset .related-sidebar p {
    font-size:14px;
    line-height:20px;
    margin-bottom:15px;
}

.asset .related-sidebar .asset-audio audio {
    display: block;
}

.asset .asset-recommend .outbrain h3 {
    font-size: 18px;
    margin: 10px;
}

.asset .popover .author-card .tagline {
    font-size: 12px;
}

.asset .asset-recommend .outbrain ul {
    padding-left: 30px;
    margin-bottom: 0;
}

.asset .asset-html .html-content {
    margin-bottom: 20px;
}

.asset .asset-author .card {
    padding-top:20px;
    border-top:1px solid rgba(0,0,0,.1);
}

.card.full-asset .card.photo-carousel {
    margin-bottom:20px;
}

.asset-related {
    margin-bottom:30px;
}

.asset-related > h4 {
    text-transform:uppercase;
    font-size:14px;
    margin:0 0 10px;
}

.asset-related .related-content {
    border-top:1px solid rgba(0,0,0,.1);
    padding-top:20px;
}

.asset-related .headline-list > li:first-child {
    padding-top:0;
}

#asset-content .inline-asset .card figure .image {
    margin:0;
}

.asset .asset-body .collection-header p {
    color:#666;
    margin-bottom:15px;
}

.asset .asset-body .collection-content .collection-card {
    margin-bottom:20px;
}

.asset .asset-fields.agent-info ul {
    list-style-type: none;
}

.asset figure .caption ul{
    margin-bottom: 0px;
}

.asset figure .caption, 
.asset figure .caption p {
    font-size: 13px;
}
.asset figure .caption .credit,
.caption-container .credit,
.caption-container .credit a,
.asset .asset-showcase-image .showcase-caption .credit,
.caption-overlay .credit,
.caption-overlay .credit a {
    font-size: 12px;
}

@media screen and (min-width: 768px) {
    .asset .asset-body.has-fixed-share {
        margin-left:100px;
        overflow: hidden;
    }
    .asset .share-container.headline-right {
        top: 0;
        right: 0;
    }
    .asset .asset-header .hammer,
    .asset .asset-header .kicker,
    .asset .asset-header .subhead,
    .asset .asset-header .meta,
    .asset .asset-header h1 {
        margin-right: 100px;
    }
    .asset.article-longform .asset-header .hammer {
        margin:0 100px;
    }
    .asset.article-longform .asset-header .kicker,
    .asset.article-longform .asset-header .subhead,
    .asset.article-longform .asset-header .meta,
    .asset.article-longform .asset-header h1,
    .asset.article-infinity-story .asset-header .kicker,
    .asset.article-infinity-story .asset-header .subhead,
    .asset.article-infinity-story .asset-header .meta,
    .asset.article-infinity-story .asset-header h1 {
        margin-right: 0;
    }
    .asset .asset-header h1 {
        font-size: 42px;
    }
    .asset .asset-header .subhead {
        font-size: 24px;
    }
    .asset .asset-masthead {
        margin:20px 0 40px;
    }
}

/* Cover art
-------------------------------------------------- */
.asset .asset-masthead.tnt-cover-art {
    animation: fadein 2s;
}

.asset .asset-masthead.tnt-cover-art #covert-art-arrow {
    color: rgba(0,0,0,.7);
    text-shadow: none;
    cursor: pointer;
    animation: bounce 2s;
}

@keyframes bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-30px);} 
   60% {transform: translateY(-15px);} 
} 

.asset .asset-masthead.tnt-cover-art .asset-header {
    animation: fadein 3s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer-border {
    width: 0px;
    margin: 0 auto 5px auto;
    height: 0px;
    border-bottom: 3px solid;
    animation: extend 3s;
    animation-fill-mode: forwards;
}
@keyframes extend {
    from { width: 0px; }
    to   { width: 100%; }
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer, 
.asset .asset-masthead.tnt-cover-art .asset-header h1, 
.asset .asset-masthead.tnt-cover-art .asset-header .kicker, 
.asset .asset-masthead.tnt-cover-art .asset-header .subhead {
    /*font-family: 'Bitter', serif;*/
    font-weight: 400;
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer {
    font-weight: 700;
    margin: 0;
    /*text-transform: none;*/
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer > span {
    line-height: 1em; 
}

.asset .asset-masthead.tnt-cover-art.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span {
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.asset .asset-masthead.tnt-cover-art .asset-header .hammer > span.bigtext-line0 {
    opacity: 1;
}

.asset .asset-masthead.tnt-cover-art .asset-header h1 {
    font-size: 25px;
    line-height: 1.1em;
}

.asset .asset-masthead.tnt-cover-art .asset-label-flags .label-flag {
    text-shadow: none;
    color: rgba(255,255,255,1);
    background-color: rgba(0,0,0,.7);
}

.tnt-text-color-light > *,
.tnt-text-color-light > a,
.tnt-text-color-light span > a,
.tnt-text-color-light li > a,
.tnt-text-color-light .text-muted {
    color: rgba(255,255,255,1);
    border-color: rgba(255,255,255,1);
    text-shadow: 1px 1px 2px rgba(0,0,0,1);
}

.tnt-text-color-dark > *,
.tnt-text-color-dark > a,
.tnt-text-color-dark span > a,
.tnt-text-color-dark li > a,
.tnt-text-color-dark .text-muted {
    color: rgba(0,0,0,1);
    border-color: rgba(0,0,0,1);
    text-shadow: 1px 1px 2px rgba(255,255,255,.5)
}

.tnt-text-color-light.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span,
.tnt-text-color-light.tnt-cover-text-bg-text-in-a-box .asset-header .headline > span,
.tnt-text-color-light.tnt-cover-text-bg-text-in-a-box .asset-header .subhead > span,
.tnt-text-color-light.tnt-cover-text-bg-text-in-a-box .asset-header .meta > span,
.tnt-text-color-light.asset-masthead.tnt-cover-art.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span {
    background-color: rgba(0,0,0,0.7);
    box-shadow: 5px 0 0 rgba(0,0,0,0.7), -5px 0 0 rgba(0,0,0,0.7);
}

.tnt-text-color-dark.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span,
.tnt-text-color-dark.tnt-cover-text-bg-text-in-a-box .asset-header .headline > span,
.tnt-text-color-dark.tnt-cover-text-bg-text-in-a-box .asset-header .subhead > span,
.tnt-text-color-dark.tnt-cover-text-bg-text-in-a-box .asset-header .meta > span,
.tnt-text-color-dark.asset-masthead.tnt-cover-art.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span {
    background-color: rgba(255,255,255,.7);
    box-shadow: 5px 0 0 rgba(255,255,255,.7), -5px 0 0 rgba(255,255,255,.7);
}

.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span,
.tnt-cover-text-bg-text-in-a-box .asset-header .headline > span,
.tnt-cover-text-bg-text-in-a-box .asset-header .subhead > span,
.tnt-cover-text-bg-text-in-a-box .asset-header .meta > span,
.asset-masthead.tnt-cover-art.tnt-cover-text-bg-text-in-a-box .asset-header .hammer > span {
    border-bottom: 0;
}

.tnt-text-color-light.tnt-cover-text-bg-background-box .asset-header {
    background-color: rgba(0,0,0,0.7);
    box-shadow: 5px 0 0 rgba(0,0,0,0.7), -5px 0 0 rgba(0,0,0,0.7);
}

.tnt-text-color-dark.tnt-cover-text-bg-background-box .asset-header {
    background-color: rgba(255,255,255,.7);
    box-shadow: 5px 0 0 rgba(255,255,255,.7), -5px 0 0 rgba(255,255,255,.7);
}

@media (min-width: 768px) {
    .asset .asset-masthead.tnt-cover-art .asset-header h1 {
        font-size: 50px;
    }
}

/* Inline assets 
-------------------------------------------------- */

/* headline link */
.tnt-inline-presentation-headline a {
    font-size: 16px;
    font-weight: bold;
}

@media (min-width: 768px) {   
    .asset.article-longform .tnt-inline-presentation-headline a {
        font-size: 18px;
    }
}

/* Articles
-------------------------------------------------- */

/* Base styles used by all article assets */
body.article .asset {}

/* Longform */
.article-longform .inline-asset.layout-horizontal .image img {
    width:100%;
}

.article-longform .asset-header .hammer,
body.article .article-longform .asset-header .kicker,
body.article .article-longform .asset-header h1 {
    margin: 0 20px;
}

@media screen and (min-width: 768px) {
    body.article .article-longform .asset-header .hammer,
    body.article .article-longform .asset-header .kicker,
    body.article .article-longform .asset-header h1 {
        margin: 0 120px;
    }
    body.article .article-longform .asset-content > p,
    body.article .article-longform .asset-content > hr,
    body.article .article-longform .asset-content > h1,
    body.article .article-longform .asset-content > h2,
    body.article .article-longform .asset-content > h3,
    body.article .article-longform .asset-content > h4,
    body.article .article-longform .asset-content > ul,
    body.article .article-longform .asset-content > ol,
    body.article .article-longform .asset-body > .share-container {
        max-width: 80%;
        margin-left:auto;
        margin-right:auto;
    }
}

/* Infinity Story */
.article-infinity-story .hammer {
    font-size: 32px;
}
.article-infinity-story .comments-container {
    padding-bottom: 20px;
    text-align: center;
}


/* Blocks
-------------------------------------------------- */

.block-title, 
.user-header {
    margin:0 0 20px;
}

.block-title {
    border-bottom:1px solid rgba(0,0,0,.1);
}

.block-title .block-title-inner {
    padding:0 15px 8px 0;
    margin-bottom:-1px;
    border-bottom:2px solid transparent;
}

.card-heading-color .block-title h2 {
    text-shadow:1px 1px 0px rgba(0, 0, 0, 0.15);
}

.block-title h2 small {
    text-shadow:none;
}

.block-title h4 {
    color:rgba(0,0,0,.5);
}

.list-inline.block-links.pos-bottom {
    margin:0;
    padding:15px 0;
    border-top:1px solid rgba(0,0,0,.1);
    border-bottom:1px solid rgba(0,0,0,.1);
}

.dark-theme .block-title, .dark-theme .list-inline.block-links.pos-bottom {
    border-color:rgba(255,255,255,.1);
}

.dark-theme .block-title h4 {
    color:rgba(255,255,255,.7);
}

/* User controls
-------------------------------------------------- */
#main-navigation .user-controls .nav.navbar-right .dropdown-menu,
#main-navigation .nav.navbar-right .search-form-dropdown .dropdown-menu {
    border-top: 0;
    margin-left: 0;
    margin-right: -1px;
}

/* Cards
-------------------------------------------------- */

.card-panel .card {
    border-bottom:none;
    padding-bottom:0;
}

.card .card-labels {
    padding-bottom: 5px;
}

.card .card-label-section {
    font-size:85%;
    text-transform:uppercase;
}

.card .card-label-section a {
    display: inline-block;
    color: var(--light-gray);
}

.card .card-label-flags {
    font-size:90%;
}

.card .card-headline h1, .card .card-headline h2, .card .card-headline h3, .card .card-headline h4, .card .card-headline h5, .card .card-headline h6 {
    font-size:18px;
    line-height:20px;
    margin-top: 0;
}

.card.mini .card-headline h1, .card.mini .card-headline h2, .card.mini .card-headline h3, .card.mini .card-headline h4, .card.mini .card-headline h5, .card.mini .card-headline h6 {
    font-size:16px;
    line-height:20px;
    margin-bottom:3px;
}

.card .card-headline a:hover {
    text-decoration:none;
}

.card-slideshow {
    margin-left:-15px;
    margin-right:-15px;
}

.card-slideshow .owl-stage-outer {
    padding-bottom:2px;
}

.owl-carousel .owl-item .card * {
    -webkit-backface-visibility: hidden;
}

.card-slideshow .owl-carousel-control-container {
    margin-bottom:10px;
    margin-top:-10px;
}

.card figure .image {
    position: relative;
}

.card-agent {
    font-size: 12px;
    line-height: 1.4em;
    border-top: 1px solid rgba(0,0,0,0.08);
    margin-top: 15px;
    margin-bottom: 0;
    padding-top: 15px;
}

@media screen and (min-width: 768px) {
    .card .card-meta {
        font-size: 85%;
    }
    .card.has-image > .card-container > .card-body {
        padding-top:0;
        padding-left:15px;
    }
    .card-img-lg .card.has-image > .card-container > .card-body {
        padding:15px;
    }
    .card-img-lg .card.has-image.image-top > .card-container > .card-body {
        padding-top:0px;
    }
    .card .card-headline h1, .card .card-headline h2 {
        font-size: 30px;
        line-height:32px;
    }
    .card .card-headline h3 {
        font-size: 22px;
        line-height:24px;
    }
    .card .card-headline h4, .card .card-headline h5, .card .card-headline h6 {
        font-size: 18px;
        line-height:20px;
    }
    .inline-asset .card .card-body {
        padding: 15px;
    }
    .inline-asset .card .card-body .card-headline h3 {
         margin: 0 0 5px 0;
        font-size: 16px;
        line-height:20px;
    }
    .inline-asset .card .card-body .card-lead {
        display:block;
    }
    .card-slideshow {
        margin-left:0;
        margin-right:0;
    }

    .card-slideshow .owl-carousel-control-container {
        display:block;
    }
}

@media (min-width: 992px) {
    .card .card-meta {
        font-size: 100%;
    }
}


/* Business Cards
-------------------------------------------------- */
.card.business {
    border-top: 1px solid rgba(0,0,0,.08);
    margin: 0;
}
.card.business.basic.mini {
    border:none;
}
.card.business .card-content > div:last-child,
.card.business .card-content > div:last-child > ul {
    margin-bottom:0;
}
.card.business .card-tag {
    position: absolute;
    top: 0;
    right: 0;
}
.card.business .card-tag .tag-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 50px 50px 0;
}
.card.business .card-tag .tag-txt {
    padding: 6px 9px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    color: #fff;
}
.card.business .card-body {
    padding: 15px 15px 15px 0;
}
.card.business.enhanced .card-body,
.card.business.power .card-body {
    padding-right: 25px;
}
.card.business.basic.mini .card-body {
    padding: 0;
}
.card.business .card-image {
    float:right;
    padding:15px 15px 10px 10px;
}
.card.business .card-image img {
    width:100px;
}
.card.business .card-image img.lazyload {
    width:auto;
}
.card.business .card-contact,
.card.business .card-lead,
.card.business .card-isopen {
    margin-bottom:10px;
    font-size:12px;
}
.card.business .card-branch {
    font-size:12px;
    font-weight: bold;
}
.card.business .card-isopen {
    color:#51B201;
}
.card.business .card-social {
    float:none;
    margin-left:0;
}
.card.business.mini .card-social .social-share-links {
    margin-bottom:0;
}
.card.business .social-share-links.list-inline>li {
    padding:0 5px 0 0;
}
.card.business.power .card-contact,
.card.business.power .card-branch,
.card.business.enhanced .card-contact,
.card.business.enhanced .card-branch,
.card.business.featured .card-contact,
.card.business.featured .card-branch {
    font-size:14px;
    line-height:1.3em;
}
.card.business.power.mini .card-contact, .card.business.enhanced.mini .card-contact, .card.business.featured.mini .card-contact {
    font-size:12px;
    line-height:1.4em;
}
.card.business .card-container .tnt-svg {
    opacity: .5;
}
.card.business.featured .card-tag .tag-bg {
    border-color: transparent #ccc transparent transparent;
}
.card.business.power .card-tag .tag-bg {
    border-color: transparent #77bf3b transparent transparent;
}
.card.business.enhanced .card-tag .tag-bg {
    border-color: transparent #589bf7 transparent transparent;
}

.business-cards.card-bg-color .panel-body {
    padding:0;
}

.business-cards.card-bg-color .panel-body .card.business {
    padding-left:15px;
}

.business-cards.card-bg-color .card.business {
    border:0;
}
.gmap_marker .card.business {
    color: #333;
    width: 550px;
    border: 0;
}
.gmap_marker .card.business .card-body {
    padding:5px;
}
.gmap_marker .card.business a {
    color: inherit!important;
}
.business-search-power .card.business {
    border:1px solid rgba(119, 191, 59, 0.25);
    padding-left:15px;
    margin:10px 0;
}
.business-search-enhanced .card.business {
    border:1px solid rgba(88, 155, 247, 0.25);
    padding-left:15px;
    margin:10px 0;
}

.card-grid.business-cards .card.business.power .card-contact, .card-grid.business-cards .card.business.enhanced .card-contact, .card-grid.business-cards .card.business.featured .card-contact {
    font-size:12px;
}

.card-grid.business-cards .card-content > div:last-child, .card-grid.business-cards .card-content > div:last-child > ul {
    margin-bottom:0;
}

.biz-panel .panel-body {
    padding: 0;
}

.biz-panel .card.business {
    padding-left: 15px;
    border-top: none;
}

@media screen and (min-width: 768px) {
    .card.business .card-container > .card-image {
        float:left;
        padding:15px 0;
        width:10%;
    }
    .card.business.featured .card-container > .card-image, .card.business.enhanced .card-container > .card-image, .card.business.power .card-container > .card-image {
        width:20%;
    }
    .card.business.has-image > .card-container > .card-body {
        margin-left:10%;
        padding:15px;
    }
    .card.business.featured.has-image > .card-container > .card-body, .card.business.power.has-image > .card-container > .card-body, .card.business.enhanced.has-image > .card-container > .card-body  {
        margin-left:20%;
    }
    .card.business .card-image img {
        width:100%;
    }

    .card-grid-multi .card.business {
        height:240px;
        overflow:hidden;
    }

    .card-grid-multi .card.business .card-body {
        padding:15px;
        position:absolute;
        left:0;
        right:0;
    }

    .card-grid-multi .card.business.has-image .card-body {
        padding-top:40px;
        bottom:0;
    }

    .card-grid-multi .card.business .card-image {
        float:none;
        padding:15px 15px 0;
    }

    .card-grid-multi .card.business .card-image img {
        width:100%;
    }

    .card-grid-multi.business-cards.card-bg-color .panel-body .card.business {
        padding-left:0;
    }
    .card-grid-multi .card.business .card-container > .card-image {
        width: 100%;
        padding:15px 15px 0;
        float: none;
    }
    .card-grid-multi .card.business.has-image > .card-container > .card-body {
        padding-top:40px;
        margin-left:0;
    }

}

@media screen and (min-width: 1200px) {
    .card.business.featured .card-container > .card-image, .card.business.enhanced .card-container > .card-image, .card.business.power .card-container > .card-image {
        width:25%;
    }
    .card.business.featured.has-image > .card-container > .card-body, .card.business.power.has-image > .card-container > .card-body, .card.business.enhanced.has-image > .card-container > .card-body  {
        margin-left:25%;
    }
    .card-grid-multi .card.business .card-container > .card-image {
        width: 100%;
        float: none;
    }
    .card-grid-multi .card.business.featured.has-image .card-container > .card-body,
    .card-grid-multi .card.business.power.has-image .card-container > .card-body,
    .card-grid-multi .card.business.enhanced.has-image .card-container > .card-body {
        margin-left:0;
    }

}


/* Featured Classifieds
-------------------------------------------------- */
.featured-classifieds .card.product > .card-container > .card-image {
    width: 100%;
    float: none;
    margin-bottom: 10px;
}
.featured-classifieds .card.product > .card-container > .card-body {
    padding-left: 0;
    margin-left: 0;
}
.featured-classifieds .card.product .card-lead {
    display: none;
}
@media screen and (min-width: 767px) {
    .featured-classifieds .card.business .card-image img {
        width: 100px;
    }
    .featured-classifieds .card.business .card-container > .card-image {
        width: 100px;
    }
    .featured-classifieds .card.business.has-image > .card-container > .card-body {
        margin-left: 100px;
    }
    .featured-classifieds .cols-4 .card.business .card-image img {
        width: auto;
    }
    .featured-classifieds .cols-4 .card.business .card-container > .card-image {
        width: 100%;
        float: none;
        padding-bottom: 0;
    }
    .featured-classifieds .cols-4 .card.business .card-container > .card-body {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .featured-classifieds .card.product .card-lead {
        display: block;
    }
}


/* Business Featured Category
-------------------------------------------------- */
.biz-featured-category .logos img {
    max-height: 200px;
    max-width: 200px;
    width: 40%;
    display: block;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.biz-featured-category .logos a:only-child img {
    max-height: 100px;
    width: auto;
    max-width: 100%;
}

/* Event Card
-------------------------------------------------- */
.card.event .card-date,
.card.event .card-venue,
.card.event .card-cost {
    margin-bottom: 2px;
}

.card.event .schedule-container {
    margin-bottom: 5px;
}


/* Card Partitions
-------------------------------------------------- */
.date-partition {
    margin:20px 0;
}

.date-partition h5 {
  position: relative;
  overflow: hidden;
  color:#777;
}

.date-partition h5 .tnt-svg {
    padding-right:5px;
}

.date-partition h5 span {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  *display: inline;
  *vertical-align: auto;
  position: relative;
  padding-right: 15px;
}

.date-partition h5 span:after {
  content: '';
  display: block;
  width: 1000px;
  position: absolute;
  top: 0.6em;
  border-top: 1px solid rgba(0,0,0,.1);
}

.date-partition h5 span:after {
  left: 100%;
}

/* Headline List
-------------------------------------------------- */

.card.headline-list {
    margin: 0;
    border: 0;
}

.headline-list.bullet-disc,
.headline-list.bullet-circle,
.headline-list.bullet-square {
    padding-left: 1.2em;
}

.headline-list.bullet-circle li {
    list-style-type: circle;
}

.headline-list.bullet-square li {
    list-style-type: square;
}

.headline-list.bullet-decimal {
    padding-left: 1.9em;
}

.headline-list.bullet-decimal li {
    list-style-type: decimal;
}

.headline-list > li {
    padding-top: 4px;
    padding-bottom: 4px;
}

.headline-list.border li {
    padding: 5px 0;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.1);
}

.light .headline-list.border li {
    border-bottom-color: rgba(255,255,255,.15);
}

.dark .headline-list.border li {
    border-bottom-color: rgba(0,0,0,.15);
}

.headline-list.border-solid li {
    border-bottom-style: solid;
}

.headline-list.border-dotted li {
    border-bottom-style: dotted;
}

.headline-list.border-dashed li {
    border-bottom-style: dashed;
}

.headline-list.border li:last-child {
    border-bottom-width: 0px;
    border-bottom-style: none;
}

.headline-list.list-unstyled .premium-asset-icon {
    margin-left: 0;
}

/* Breaking
-------------------------------------------------- */
.breaking .breaking-container {
    max-width:1170px;
    margin:0 auto;
}

.breaking .breaking-title {
    display:inline-block;
    padding:5px 10px;
    margin:10px 10px 0 10px;
    overflow:hidden;
}

.breaking .breaking-title h4 {
    font-size:12px;
    line-height:1em;
    text-transform:uppercase;
    margin:0;
}

.breaking .breaking-title.light, .block.light .breaking .card a, .block.light .breaking .card a:hover {
    color:#fff;
}

.breaking .breaking-title.dark, .block.dark .breaking .card a, .block.dark .breaking .card a:hover {
    color:#222;
}

.block.light .breaking .card .text-muted, .block.light .breaking .card .cm {
    color:rgba(255,255,255,.8);
}

.block.dark .breaking .card .text-muted, .block.dark .breaking .card .cm {
    color:rgba(0,0,0,.7);
}

.breaking .breaking-content {
    display:block;
}

.breaking .breaking-content .card {
    margin:0;
    padding:5px 10px 10px;
    border-top:none;
}

.breaking .breaking-content .card-meta {
    display:none;
}

.breaking .breaking-content .card .card-headline > * {
    margin:0;
}

.breaking .breaking-content .card .list-inline {
    margin:0;
}

.breaking .owl-theme .owl-controls {
    margin:0;
    position:absolute;
    bottom:0;
    right:0;
}

.breaking .owl-theme .owl-controls .owl-nav [class*='owl-'] {
    background-color:transparent;
}

.block.dark .breaking .owl-theme .owl-controls .owl-nav [class*='owl-'] {
    color:#222;
}

.breaking .owl-theme .owl-controls .owl-nav [class*='owl-']:hover {
    background-color:rgba(0,0,0,.2);
}

@media screen and (min-width: 768px) {
    .breaking .breaking-title {
        display:block;
        margin:7px;
        padding:10px 15px;
        width:125px;
        float:left;
    }

    .breaking.show-images .breaking-title {
        min-height:86px;
    }

    .breaking .breaking-title h4 {
        font-size:18px;
    }

    .breaking .breaking-content {
        margin-left:145px;
    }

    .breaking .breaking-content .card {
        margin:0;
        padding:0;
    }

    .breaking .breaking-content .card-meta {
        display:block;
    }

    .breaking .breaking-content .card .card-body, .breaking .breaking-content .card .card-image {
        padding-top:7px;
        padding-bottom:7px;
    }

    .breaking .breaking-content .card .card-headline > h3 {
        padding-top:7px;
        padding-bottom:7px;
    }

    .breaking .breaking-content .card .card-headline > h2 {
        padding-top:3px;
        padding-bottom:3px;
    }

}


/* Updates
-------------------------------------------------- */
.card.updates {
    margin: 0;
    padding: 0;
    position: relative;
}

.updates-item {
    margin: 0;
    position: relative;
    padding: 0 0 20px 25px;
}

.updates-item:last-child {
    padding: 0 0 5px 25px;
}

.updates-item .card-datetime {
    margin-bottom: 10px;
}

/* timeline line */
.updates-item::before {
    content: '';
    display: block;
    width: 2px;
    background: #BBB;
    position: absolute;
    top: 15px;
    left: 5px;
    bottom: -5px;
}

/* timeline recent item line color */
.updates-item.updates-recent::before {
    background: #E9534F;
}

/* timeline last line fade */
.updates-item:last-child::before {
    background: linear-gradient(to bottom, #BBB, #BBB 50%, transparent);
}

/* timeline recent item last line fade */
.updates-item.updates-recent:last-child::before {
    background: linear-gradient(to bottom, #E9534F, #E9534F 50%, transparent);
}

/* timeline dot */
.updates-item::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: transparent;
    position: absolute;
    top: 4px;
    left: 0;
    border: 2px solid #BBB;
    border-radius: 50%;
}

/* timeline recent item dot */
.updates-item.updates-recent::after {
    background-color: transparent;
    border: 2px solid #E9534F;
    -webkit-animation: updates-timeline-color 1.3s infinite;
    animation: updates-timeline-color 1.3s infinite;
}

/* timeline recent item dot animation */
@-webkit-keyframes updates-timeline-color {
    0% { background-color: transparent; }
    50% { background-color: #E9534F; }
    100% { background-color: transparent; }
}

@keyframes updates-timeline-color {
    0% { background-color: transparent; }
    50% { background-color: #E9534F; }
    100% { background-color: transparent; }
}

.updates-item .card-headline h2,
.updates-item .card-headline h3,
.updates-item .card-headline h4 {
    line-height: 1.2;
}

.updates-item .card-headline h2 {
    font-size: 22px;
}

.updates-item .card-headline h3 {
    font-size: 18px;
}

.updates-item .card-headline h4 {
    font-size: 16px;
}

.updates-top-story-display .updates-item:first-child .card-headline h2 {
    font-size: 26px;
}
.updates-top-story-display .updates-item:first-child .card-headline h3 {
    font-size: 22px;
}
.updates-top-story-display .updates-item:first-child .card-headline h4 {
    font-size: 20px;
}

.updates-items-container .updates-item .card-lead {
    display: none;
}
.updates-items-container.updates-top-story-display .updates-item:first-child .card-lead {
    display: block;
}

.updates-item > .card-container > .card-image {
    width: 20%;
    float: left;
    overflow: hidden;
    position: relative;
}

.updates-item.has-image > .card-container > .card-body {
    margin-left: 20%;
    padding-left: 15px;
}

.updates-item .card-meta,
.updates-item .card-social {
    margin-bottom: 10px;
}

.updates-item > .card-container > .card-body > *:last-child,
.updates-item > .card-container > .card-body > *:last-child h2,
.updates-item > .card-container > .card-body > *:last-child h3,
.updates-item > .card-container > .card-body > *:last-child h4,
.updates-item > .card-container > .card-body > *:last-child p,
.updates-item > .card-container > .card-body > * ul:last-child,
.updates-item > .card-container > .card-body .social-share-links {
    margin-bottom: 0;
}

.updates-container + .list-inline.block-links.pos-bottom {
    margin-top: 10px;
}

.updates-btn-container {
    height: 0px;
    margin-bottom: 0px;
    transition: .3s all;
}
.updates-btn-container.shown {
    height: 34px;
    margin-bottom: 20px;
}

.updates-btn-container .btn {
    opacity: 0;
    transform: scale(0);
    transition: .3s all;
}
.updates-btn-container.shown .btn {
    opacity: 1;
    transform: scale(1);
}


/* Most Popular
-------------------------------------------------- */

.most-popular figure {
    margin: 0;
}

.most-popular .nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
}

.most-popular .no-results {
    font-style: italic;
    margin: 0;
}

.most-popular .tab-content {
    border: 1px solid #DDD;
    padding: 15px;
}

.most-popular .tab-content-list {
    column-count: 2;
    column-width: 170px;
    margin: 0;
    padding: 0;
}

.most-popular .tab-pane-articles li,
.most-popular .tab-pane-commented li,
.most-popular .tab-pane-collections li,
.most-popular .tab-pane-audio li {
    color: #646464;
}

.most-popular .tab-pane-images .tab-content-list,
.most-popular .tab-pane-videos .tab-content-list {
    margin: 0;
    padding: 0;
}

.most-popular .tab-pane-images li,
.most-popular .tab-pane-videos li {
    display: inline-block;
    list-style-type: none;
}

.most-popular .tab-pane-images li img,
.most-popular .tab-pane-videos li img {
    max-height: 79px;
    width: auto;
    object-fit: inherit;
}

.most-popular .tab-content-heading {
    display: none;
    margin-top: 0;
}

.most-popular .tab-pane-articles li,
.most-popular .tab-pane-audio li,
.most-popular .tab-pane-collections li,
.most-popular .tab-pane-commented li {
    padding: 4px 0;
}

.most-popular .tab-content-list.border li {
    padding: 5px 0;
    border-bottom-width: 1px;
    border-bottom-color: #DDD;
}

.most-popular .tab-content-list.border-solid li {
    border-bottom-style: solid;
}

.most-popular .tab-content-list.border-dotted li {
    border-bottom-style: dotted;
}

.most-popular .tab-content-list.border-dashed li {
    border-bottom-style: dashed;
}

.most-popular .tab-content-list.border li:last-child {
    border-bottom-width: 0px;
    border-bottom-style: none;
}

.most-popular .tab-content-list.bullet-disc,
.most-popular .tab-content-list.bullet-circle,
.most-popular .tab-content-list.bullet-square {
    padding-left: 1.2em;
}

.most-popular .tab-content-list.bullet-circle {
    list-style-type: circle;
}

.most-popular .tab-content-list.bullet-square {
    list-style-type: square;
}

.most-popular .tab-content-list.bullet-decimal {
    list-style-type: decimal;
    padding-left: 1.9em;
}


/* Numbered Most Popular
-------------------------------------------------- */

.list-popular {
    counter-reset: list-popular;
    margin:0;
}

.list-popular.col-auto {
    column-count: 2;
    column-width: 170px;
}

.list-popular.col-auto > li {
    display: inline-block;
}

.list-popular > li {
    display:block;
    margin-bottom:15px;
}

.list-popular > li:last-child {
    margin-bottom:0;
}

.list-popular > li h3, 
.list-popular > li h4, 
.list-popular > li h5 {
    margin:0 0 0 38px;
    min-height:23px;
    line-height:1.2em;
}

.list-popular > li h3 {
    font-size:18px;
}

.list-popular > li a {
    display:block;
}

.list-popular > li a .comment-count {
    font-size:80%;
    line-height:100%;
    display:inline-block;
    padding-left:5px;
}

.list-popular > li:before {
    display:block;
    float:left;
    width:23px;
    height:23px;
    line-height:23px;
    color:#fff;
    font-size:16px;
    font-weight:bold;
    background-color:#222;
    text-align:center;
    margin-right:15px;
    content:counter(list-popular);
    counter-increment: list-popular;
}

@media screen and (min-width: 768px) {

    .list-popular.bold > li {
        background-color:rgba(0,0,0,.04);
        margin-bottom:1px;
    }

    .list-popular.bold > li a {
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }

    .list-popular.bold > li:before {
        width:47px;
        height:47px;
        line-height:47px;
        font-size:20px;
    }

    .list-popular.bold > li h3, .list-popular.bold > li h4, .list-popular.bold > li h5 {
        margin:0;
        line-height:47px;
    }


    .list-popular > li h3 {
        font-size:24px;
    }

}

/* Featured Events
-------------------------------------------------- */
.featured-events .panel-body {
    padding: 0;
}
.block.light .featured-events .photo-carousel-control {
    color: rgba(255,255,255,.4);
    background-color: rgba(0,0,0,0.9);
}
.block.dark .featured-events .photo-carousel-control {
    color: rgba(0,0,0,0.4);
    background-color: rgba(255,255,255,.9);
}
.block.light .featured-events .photo-carousel-control:hover {
    color: rgba(255,255,255,0.8);
    background-color: #000;
}
.block.dark .featured-events .photo-carousel-control:hover {
    color: rgba(0,0,0,0.8);
    background-color: #FFF;
}
.featured-events .text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
}
.featured-events .no-img .text-wrapper {
    background-color: transparent;
    text-shadow: none;
}
.featured-events .no-img .text-wrapper {
    background-color: transparent;
    text-shadow: none;
}
.featured-events .text-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    padding: 15px;
}
.featured-events .no-img .card-social,
.featured-events .no-img .card-social ul {
    margin: 0;
}
.featured-events .date-cond{
    display: block;
}
.featured-events .date-full,
.featured-events .card-social,
.featured-events .card-venue,
.featured-events .card-cost {
    display: none;
}
.featured-events.size-370 .date-cond {
    display: none;
}
.featured-events.size-370 .card-social,
.featured-events.size-370 .date-full {
    display: block;
}
.featured-events.size-370 .has-img .text-wrapper {
    display: block;
    text-align: left;
}
.featured-events.size-370 .has-img .text-content {
    display: block;
}
.featured-events.size-370 .card-venue,
.featured-events.size-370 .card-cost {
    display: block;
}
.featured-events .card-headline h2,
.featured-events .card-headline h3,
.featured-events .card-headline h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.featured-events.size-370 .card-headline h2,
.featured-events.size-370 .card-headline h3,
.featured-events.size-370 .card-headline h4 {
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
}


/* Utility Email Signup
-------------------------------------------------- */
.block .mailinglist-container .mailinglist-item {
    padding: 15px 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin: 0;
}
.block .mailinglist-container .mailinglist-item.only-one {
    padding: 15px 0px;
}
.block .mailinglist-instructions {
    margin-bottom: 20px;
}
.block .mailinglist-container .form-group .mailinglist-item:first-child {
    padding-top: 0;
}
.block .mailinglist-container .form-group .mailinglist-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.block .mailinglist-container .mailinglist-item p:last-child {
    margin: 0;
}
.block .mailinglist-container .mailinglist-title {
    margin: 0;
}
.block .mailinglist-container .form-group.has-desc .mailinglist-item:first-child {
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 15px;
}
.block .mailinglist-container .mailinglist-btn-container + .mailinglist-btn-container {
    padding-top: 5px;
}
@media(min-width:768px){
    .block .mailinglist-container .mailinglist-btn-container + .mailinglist-btn-container {
        padding-top: 0px;
    }
}
.block .mailinglist-container .mailinglist-select-all-container {
    margin-left: 10px;
    margin-bottom: 15px;
}


/* Utility Text Promo
-------------------------------------------------- */
.card.promo .img-thumbnail {
    background-color: rgba(255,255,255,0.5);
    border: 1px solid rgba(0,0,0,0.1);
}
.card.promo .media-body button {
    white-space: normal;
}
.card.promo .media-object {
    display: inline-block;
}
.card.promo.media{
    margin-top: 0px;
}



/* Utility Subscription Services
-------------------------------------------------- */
.subscription-service .service-price-text {
    font-size: 22px;
}

.subscription-service .service-sublink .btn {
    display: block;
    width: 100%;
}

.subscription-service .service-rates {
    margin-bottom: 10px;
}

.subscription-service .collapse-rates table {
    margin-bottom: 0px;
    margin-top: 10px;
}

.subscription-service .collapse-rates .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,0.03);
}

/* the following line can be removed when we update bootstrap - github.com/twbs/bootstrap/pull/13920 */
.subscription-service .collapse-rates .table-striped > tbody > tr:nth-child(odd) > td {
    background-color: transparent;
}

@media (min-width: 768px) {
    .subscription-service .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .subscription-service .align-bottom {
        align-self: flex-end;
    }
    .subscription-service .service-sublink .btn {
        display: inline-block;
        width: auto;
    }
    .subscription-service .service-rates {
        margin-bottom: 0px;
    }
}


/* Contribute Wrapper
-------------------------------------------------- */
.btn-contrib {
    font-weight: normal;
}


/* Ads
-------------------------------------------------- */

.ad-separator-top {
    margin-top:20px;
    padding-top:20px;
    border-top:1px solid rgba(0,0,0,.1);
}

.ad-separator-bottom {
    margin-bottom:20px;
    border-bottom:1px solid rgba(0,0,0,.1);
}

.dark-theme .ad-separator-top, .dark-theme .ad-separator-bottom {
    border-color:rgba(255,255,255,.1);
}


/* Footer
-------------------------------------------------- */

#site-footer {
    padding: 15px 20px 0 20px;
    margin-bottom: 10px;
}

#site-footer .block-title h4 {
    color:inherit;
}

#site-copyright {
    padding-top:20px;
    padding-bottom:20px;
}

@media screen and (max-width: 991px) {
    #site-copyright {
        font-size: 80%;
    }
    #site-copyright .text-right {
        text-align:left!important;
    }
}

/* Status pages
-------------------------------------------------- */
#status-404,
#status-410 {
    margin-bottom:80px;
}

#status-404 .page-header,
#status-410 .page-header {
    margin-bottom:0;
}

.img-404 .cite,
.img-410 .cite {
    text-align:right;
}

.img-404 .cite a,
.img-410 .cite a {
    font-size:10px;
    font-style:italic;
    color:#c0c0c0;
}

#status-404 .error-code,
#status-410 .error-code {
    font-weight:bold;
    color:#828282;
    cursor:default;
}

@media (min-width: 768px) {
    #status-404 .page-header,
    #status-410 .page-header {
        margin-bottom:20px;
    }
}

/* ???
-------------------------------------------------- */

/* the form border-radius comeback */
.search-calendar .btn,
.search-calendar .form-control{
    border-radius:3px !important;
}

/* Related content
-------------------------------------------------- */
.asset-body .related-wrapper p:last-child,
.asset-body .related-wrapper blockquote,
.asset .related-sidebar .related-wrapper p:last-child{
    margin-bottom: 0;
}
.asset .related-wrapper {
    margin-bottom: 20px;
}